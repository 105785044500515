input[type="range"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #eef1f6;
  border-radius: 3px;
  height: 6px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #724D7B;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-moz-range-thumb {
  background-color: #724D7B;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-ms-thumb {
  background-color: #724D7B;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}


.pricing {
  width: 70%;
  margin: auto;
}
.pricing_slider {
  max-width: 280px;
  margin: 0 auto;
}

.form_slider span {
  display: block;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
}

.pricing_slider {
  margin-bottom: 48px;
}

.pricing-slider {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.pricing_slider input {
  width: 100%;
}
.pricing_slider .pricing_slider-value {
  position: absolute;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #909cb5;
  margin-top: 8px;
  --thumb-size: 36px;
}
.pricing_item_price {
  display: inline-flex;
  align-items: baseline;
  font-size: 20px;
}

.pricing_item_price_amount {
  font-size: 36px;
  line-height: 48px;
  font-weight: 500;
  color: #191e2a;
}
.heading{
text-align: center;
margin-bottom: 100px;
}
 
